import React from 'react';
import { navigate } from "gatsby"

import { iosLink, androidLink } from '../assets/constants';

function getMobileOperatingSystem() {
  if (typeof window !== `undefined`) {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
      return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
  }

  return "unknown";
  }
}

function DetectAndServe(){
  if (typeof window !== `undefined`) {
    let os = getMobileOperatingSystem();
    console.log(os);
    if (os == "Android") {
      navigate(androidLink);
    } else if (os == "iOS") {
      navigate(iosLink);
    } else {
      navigate("/");
    }
  }
}


const IndexPage = () => (
  <>
    {DetectAndServe()}
  </>
);

export default IndexPage;
